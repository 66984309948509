(function ($) {
  customerServiceV1 = {
    formatter: $('.cs-formatter-v1')
  };

  Drupal.behaviors.customerServiceV1 = {
    get: function (key) {
      return customerServiceV1[key];
    },

    set: function (key, val) {
      customerServiceV1[key] = val;
    },

    attach: function (context, settings) {
      var self = this;
      var formatter = self.get('formatter');

      formatter.each(function (i) {
        var thisFormatter = $(this);
      // code goes here...
      });
    }
  };
})(jQuery);
